p-calendar {
  &.ng-dirty {
    &.ng-invalid {
      > .p-calendar {
        > .p-inputtext {
          @apply
            tw-border-border-danger
        }
      }
    }
  }
}

.p-datepicker {
  @apply
    tw-p-spacing-8;

  .p-datepicker-header {
    .p-datepicker-prev,
    .p-datepicker-next {
      @apply
        tw-cursor-pointer
        enabled:hover:tw-bg-bg-tertiary
        enabled:hover:tw-text-text-primary
        enabled:hover:tw-border-border-primary
        enabled:focus:tw-shadow-none
        enabled:focus:tw-border-border-primary
    }

    .p-datepicker-title {
      .p-datepicker-year,
      .p-datepicker-month {

        &:enabled {
          @apply
            hover:tw-text-text-tertiary;
        }
      }
    }
  }

  table {
    @apply
      tw-m-spacing-0;

    th {
      @apply
        tw-p-spacing-12
        tw-bg-bg-tertiary-light;

      > span {
        @apply
          tw-font-medium;
      }
    }

    td {
      @apply
        tw-p-spacing-2;

      > span {
        @apply
          tw-w-40-px
          tw-h-40-px
          tw-rounded-[20px]
          focus:tw-shadow-none;

        &.p-highlight {
          @apply
            tw-text-white
            tw-bg-bg-brand-primary;
        }
      }

      &.p-datepicker-today {
        > span {
          @apply
            tw-bg-white
            tw-text-black
            tw-border-border-brand;

          &.p-highlight {
            @apply
              tw-text-white
              tw-bg-bg-brand-primary;
          }
        }
      }
    }
  }

  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        @apply
          tw-text-white
          tw-bg-bg-brand-primary;
      }
    }
  }

  .p-yearpicker {
    .p-yearpicker-year {
      &.p-highlight {
        @apply
          tw-text-white
          tw-bg-bg-brand-primary;
      }
    }
  }

  &:not(.p-disabled) {
    table {
      td {
        span:not(.p-highlight):not(.p-disabled) {
          @apply  
            hover:tw-bg-bg-tertiary-light
            focus:tw-shadow-none;
        }
      }
    }

    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          @apply
            focus:tw-shadow-none;

          &:not(.p-highlight) {
            @apply  
              hover:tw-bg-bg-tertiary-light;
          }
        }
      }
    }

    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          @apply
            focus:tw-shadow-none;

          &:not(.p-highlight) {
            @apply  
              hover:tw-bg-bg-tertiary-light;
          }
        }
      }
    }
  }
}

.p-calendar {
  &.p-calendar-w-btn {
  
    > .p-button {
      @apply
        tw-bg-white
        tw-text-text-disable
        tw-border-border-secondary
        tw-border-l-0
        enabled:focus:tw-shadow-none
        enabled:focus:tw-border-border-secondary;
  
      .p-icon {
        @apply
          tw-w-16-px
          tw-h-16-px;
      }
    }
  }

  .p-inputtext {
    @apply tw-rounded-none;
  }

  &:not(.p-calendar-disabled).p-focus > .p-inputtext {
    @apply
      tw-shadow-none;
  }
}
